.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html, body {
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  margin: 0;
  padding: 0;
}
body {
  /* background-image: linear-gradient(to right, #243949 0%, #517fa4 100%); */
  background-color: #0c0f38 !important;
  font-family: "Oxygen", "Trebuchet MS", Arial, sans-serif;
  font-size: 100%;
  color: white !important;
  position: relative;
}

.container {
  max-width: 1300px;
  height: 100%;
  margin: 0 auto;
  padding: 0 25px;
}

h1.title {
	font-size: 3em;
	display: inline-block;
	margin-right: 1em;
}

h1 {
	font-size: 2.25em;
	margin-bottom: .75em;
}

.subheading {
	margin-top: -1.25em;
}

/* Players flex container */
.players {
	max-width: 960px;
	margin: 0px auto;
  display: flex;
  flex-flow: row wrap;
	justify-content: center;

}

.players section {
	margin: 1.5rem;
	flex: 0 0 380px;
	padding: 15px;
	background-color: #f3efe5;
  border: 2px solid #69ba5e;
  border-radius: 10px;
  text-align: center;
  position: relative;
}

@media (max-width: 979px) {
	.players section {
		flex: 1 0 380px;
		max-width: 500px;
	}
}

@media (max-width: 500px) {
  h1.title {
    font-size: 2.5em;
  }
  .players section {
    flex: 0 0 90%;
    margin: 1em;
  }
}

.players section .button {
	margin: .25em;
}

/* Buttons */
.button {
  padding: 3px 24px;
  border-radius: 5px;
  border: none;
  background-color: transparent;
  color: #ffffff;
  font-size: 3.375em;
  text-decoration: none;
  text-align: center;
  display: inline-block;
  transition: background-color .15s ease-in-out, border .15s ease-in-out, color .15s ease-in-out;
}
.full {
  color: #fff;
  background-color: #69ba5e;
}
.button:hover {
  color: #fff;
  background-color: #243949;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

a.source-link {
	position: absolute;
	font-size: .8em;
	color: #41392f;
	opacity: .8;
	text-decoration: none;
	right: 1em;
	bottom: 1em;
}

a.source-link:hover {
	opacity: 1;
}

/* Github Link */
.github-btn {
  width: 38px;
  height: 38px;
  top: 1.5em;
  right: 1.5em;
  position: absolute;
  opacity: 0.8;
  display: block;
  text-decoration: none;
  transition: opacity .15s ease-in-out;
}
.github-btn:hover {
  opacity: 1;
}


/* Player Specific Styles */
.full-control {
  max-width: 480px;
    /* width: fit-content; */
    background-color: rgba(0,0,0,0.2);
    padding: 1rem;
    border-radius: 1rem;
    margin: 0 auto;
}

.full-control .toggles label:first-of-type {
  margin-right: 1.5em;
}

.full-control .toggles input {
  margin-left: .5em;
}

.full-control .volume,
.full-control .seek {
  margin-bottom: .5em;
}

.full-control .volume .slider-container,
.full-control .seek .slider-container {
  margin: auto .5em;
}

.rate {
  margin-bottom: 0.5em;
}


.container{
  display: block;
  position: relative;
  margin: 0px auto;
  height: auto;
  width: 500px;
  padding: 20px;
}

h2 {
	color: #AAAAAA;
}

.container ul{
  list-style: none;
  margin: 0;
  padding: 0;
	overflow: auto;
}

ul li{
  color: #AAAAAA;
  display: block;
  position: relative;
  float: left;
  /* width: 100%; */
  height: 100px;
	/* border-bottom: 1px solid #333; */
}

ul li input[type=radio]{
  position: absolute;
  visibility: hidden;
}

ul li label{
  display: block;
  position: relative;
  font-weight: 300;
  font-size: 1.35em;
  padding: 18px 61px 47px 87px;
  margin: 10px auto;
  height: 30px;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
}

ul li:hover label{
	color: #FFFFFF;
}

ul li .check{
  display: block;
  position: absolute;
  border: 5px solid #AAAAAA;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  top: 30px;
  left: 20px;
	z-index: 5;
	transition: border .25s linear;
	-webkit-transition: border .25s linear;
}

ul li:hover .check {
  border: 5px solid #FFFFFF;
}

ul li .check::before {
  display: block;
  position: absolute;
	content: '';
  border-radius: 100%;
  height: 15px;
  width: 15px;
  top: 5px;
	left: 5px;
  margin: auto;
	transition: background 0.25s linear;
	-webkit-transition: background 0.25s linear;
}

input[type=radio]:checked ~ .check {
  border: 5px solid #1C75A2;
}

input[type=radio]:checked ~ .check::before{
  background: #1C75A2;
}

input[type=radio]:checked ~ label{
  color: #1C75A2;
}

.signature {
	margin: 10px auto;
	padding: 10px 0;
	width: 100%;
}

.signature p{
	text-align: center;
	font-family: Helvetica, Arial, Sans-Serif;
	font-size: 0.85em;
	color: #AAAAAA;
}

.signature .much-heart{
	display: inline-block;
	position: relative;
	margin: 0 4px;
	height: 10px;
	width: 10px;
	background: #AC1D3F;
	border-radius: 4px;
	-ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.signature .much-heart::before, 
.signature .much-heart::after {
	  display: block;
  content: '';
  position: absolute;
  margin: auto;
  height: 10px;
  width: 10px;
  border-radius: 5px;
  background: #AC1D3F;
  top: -4px;
}

.signature .much-heart::after {
	bottom: 0;
	top: auto;
	left: -4px;
}

.signature a {
	color: #AAAAAA;
	text-decoration: none;
	font-weight: bold;
}


/* Styles for alert... 
by the way it is so weird when you look at your code a couple of years after you wrote it XD */

.alert {
	box-sizing: border-box;
	background-color: #BDFFE1;
	width: 100%;
	position: relative; 
	top: 0;
	left: 0;
	z-index: 300;
	padding: 20px 40px;
	color: #333;
}

.alert h2 {
	font-size: 22px;
	color: #232323;
	margin-top: 0;
}

.alert p {
	line-height: 1.6em;
	font-size:18px;
}

.alert a {
	color: #232323;
	font-weight: bold;
}


.audio-recorder{
  margin-left: auto;
  margin-right: auto;
  background-color: #1C75A2 !important;
  box-shadow: none;
}

audio:hover, audio:focus, audio:active
{
-webkit-box-shadow: 15px 15px 20px rgba(0,0, 0, 0.4);
-moz-box-shadow: 15px 15px 20px rgba(0,0, 0, 0.4);
box-shadow: 15px 15px 20px rgba(0,0, 0, 0.4);
-webkit-transform: scale(1.05);
-moz-transform: scale(1.05);
transform: scale(1.05);
}


audio
{
-webkit-transition:all 0.5s linear;
-moz-transition:all 0.5s linear;
-o-transition:all 0.5s linear;
transition:all 0.5s linear;
-moz-box-shadow: 2px 2px 4px 0px #ffffff;
-webkit-box-shadow:  2px 2px 4px 0px #ffffff;
box-shadow: 2px 2px 4px 0px #ffffff;
-moz-border-radius:32px 32px 32px 32px ;
-webkit-border-radius:32px 32px 32px 32px ;
border-radius:32px 32px 32px 32px ;
}

.nav-cust.bg-light{
  color: white !important;
  background-color: transparent !important;
  
} 
.navbar-brand{
  color: white !important;
  
}
.navbar-toggler{
  background-color: white !important;
}

.dropzone{flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 10px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #ffffff14;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}

.row {
  --bs-gutter-x: 0 !important;
}